




































































































import Vue from 'vue'
import { mapState } from 'vuex'
import { ApiResponse, Invoice, Sale, SoldItem } from '@/interfaces'

interface NewInvoiceData {
  saleId: number
  structureId: number
  type: string
  state: string
  invoicingDate: Date
  dueDate: Date
  buyerId?: number
  sellerId?: number // TODO : remove, replaced by projectId
  requerantId?: number // TODO : remove, replaced by projectId
  index?: number
  projectId?: number | null
}

export default Vue.extend({
  name: 'ReceiptList',
  props: {
    sale: {
      type: Object as () => Sale,
      required: false,
      default: null,
    },
    documentsToGenerate: {
      type: Array,
      required: false,
      default: null,
    },
    documentType: {
      type: String,
      required: false,
      default: null,
    },
    generateAllReceiptsTrigger: {
      type: Boolean,
      required: false,
      default: false,
    },
    generateAllBillsTrigger: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      busy: false,
      generatedIndex: -1,
      receiptsToGenerate: [] as SoldItem[],
      billsToGenerate: [] as SoldItem[],
      generatedReceipts: [] as Invoice[],
      generatedBills: [] as Invoice[],
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    generateAllReceiptsTrigger() {
      this.generateAllReceipts()
    },
    generateAllBillsTrigger() {
      this.generateAllBills()
    },
  },
  methods: {
    formatCurrency(number: number | null): string {
      if (!number) {
        number = 0
      }
      return Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(number)
    },
    capitalizeStr(value: string) {
      const arr = value.split(' ')

      for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
      }

      return arr.join(' ')
    },
    getFullName(soldItem: SoldItem): string {
      if (soldItem.requerantId) {
        return this.getSellingFullName(soldItem)
      } else if (soldItem.buyerId) {
        return this.getBuyingFullName(soldItem)
      }
      return 'Non renseigné'
    },
    getSellingFullName(soldItem: SoldItem): string {
      let name = ''

      if (soldItem.requerantId && soldItem.sellerId && soldItem.requerantId === soldItem.sellerId) {
        name = this.getRequerantFormName(soldItem)
      } else {
        if (soldItem.requerantId) {
          name = this.getRequerantFormName(soldItem)
        }
        if (soldItem.sellerId) {
          name = this.getSellerFormName(soldItem)
        }
      }
      return name
    },
    getRequerantFormName(soldItem: SoldItem): string {
      let name = ''
      if (soldItem.requerantCompanyName) {
        name = `${soldItem.requerantCompanyName}`
      } else {
        if (soldItem.requerantLastName) {
          name = `${soldItem.requerantLastName}`
        }
        if (soldItem.requerantFirstName) {
          name = `${name} ${soldItem.requerantFirstName}`
        }
        if (soldItem.requerantCivility) {
          name = `${name} (${soldItem.requerantCivility})`
        }
      }
      return name
    },
    getBuyingFullName(soldItem: SoldItem): string {
      let name = ''

      if (soldItem.buyerCompanyName) {
        name = `${soldItem.buyerCompanyName}`
      } else {
        if (soldItem.buyerLastName) {
          name = `${soldItem.buyerLastName}`
        }
        if (soldItem.buyerFirstName) {
          name = `${name} ${soldItem.buyerFirstName}`
        }
        if (soldItem.buyerCivility) {
          name = `${name} (${soldItem.buyerCivility})`
        }
      }
      return name
    },
    getSellerFormName(soldItem: SoldItem): string {
      let name = ''
      if (soldItem.sellerCompanyName) {
        name = `${soldItem.sellerCompanyName}`
      } else {
        if (soldItem.sellerLastName) {
          name = `${soldItem.sellerLastName}`
        }
        if (soldItem.sellerFirstName) {
          name = `${name} ${soldItem.sellerFirstName}`
        }
        if (soldItem.sellerCivility) {
          name = `${name} (${soldItem.sellerCivility})`
        }
      }
      return name
    },
    getFullAddress(soldItem: SoldItem): string {
      let address = soldItem.address ? soldItem.address + ' ' : ''
      if (soldItem.postalCode) {
        address += soldItem.postalCode + ' '
      }
      if (soldItem.city) {
        address += soldItem.city
      }
      return address ?? ''
    },
    getClientId(soldItem: SoldItem): string {
      if (soldItem.requerantId && !soldItem.sellerId) {
        return this.getRequerantId(soldItem)
      }
      return (this.documentType === 'receipt' ? soldItem.buyerId?.toString() : soldItem.sellerId?.toString()) ?? ''
    },
    getRequerantId(soldItem: SoldItem): string {
      return soldItem.requerantId?.toString() ?? ''
    },
    getClientType() {
      return this.documentType === 'receipt' ? 'Acquéreur' : 'Vendeur'
    },
    getClientTypeAddress() {
      return this.documentType === 'receipt' ? 'Adresse acquéreur' : 'Adresse vendeur'
    },
    getDocumentAction() {
      return this.documentType === 'receipt' ? 'Générer le bordereau' : 'Générer le décompte vendeur'
    },
    countItems(soldItem: SoldItem) {
      if (soldItem) {
        if (soldItem?.countItems === 1) {
          return '1 lot'
        } else {
          return soldItem?.countItems + ' lots' ?? '0 lot'
        }
      }
      return '0 lot'
    },
    prepareDataToGenerate(soldItem: SoldItem, index: number, invoiceType: string, multiple = false) {
      if (invoiceType === 'receipt') {
        invoiceType = 'bordereau'
      } else if (invoiceType === 'bill') {
        invoiceType = 'decompte'
      }
      const dueDate = new Date()
      const duePeriod = soldItem?.duePeriod ?? 0
      dueDate.setDate(dueDate.getDate() + duePeriod)
      // index variable is here to avoid multiple invoice with same temporary invoiceNumber, see createTemporaryInvoiceNumber() in InvoiceHelper.php (API)
      this.generatedIndex = index
      if (!multiple) {
        index = 0
      }
      const invoiceData = [this.getInvoiceData(soldItem, dueDate, index)]
      this.generate(invoiceData, invoiceType)
    },
    generate(invoiceData: NewInvoiceData[], invoiceType: string, multiple = false) {
      if (!this.busy) {
        this.busy = true
        const loading = this.$loading({
          target: '#receiptList',
          text: 'Chargement des données...',
        })

        let createdInvoice = {} as Invoice
        let success = false
        this.$api
          .post(`/invoice/sale`, invoiceData)
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            apiResponse.data.forEach((invoice: Invoice) => {
              this.$emit('pushInvoice', invoice)
              if (invoiceType === 'bordereau') {
                this.generatedReceipts.push(apiResponse.data)
              } else if (invoiceType === 'decompte') {
                this.generatedBills.push(apiResponse.data)
              }
              createdInvoice = invoice
            })

            if (!multiple) {
              this.$emit('removeDocument', this.generatedIndex)

              this.$notify({
                type: 'success',
                title: 'Succès',
                message: this.getSuccessMessage(),
              })
              if (createdInvoice) {
                this.$router.push({
                  name: 'invoice_edit',
                  params: {
                    id: createdInvoice.id?.toString() ?? '',
                  },
                })
              }
            } else {
              let i = 0
              while (i < this.generatedIndex) {
                this.$emit('removeDocument', i)
                i++
              }
            }
            success = true
          })
          .catch((error) => {
            if (error.response) {
              const apiResponse = error.response.data as ApiResponse

              this.$notify({
                type: 'error',
                title: 'Erreur',
                message:
                  apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
          })
          .finally(() => {
            if (success && multiple) {
              // Only notify the end of the operation if we had more than one receipt to generate
              if (this.generateAllBillsTrigger) {
                this.$emit('resetGenerateBillsTrigger')
              } else if (this.generateAllReceiptsTrigger) {
                this.$emit('resetGenerateReceiptsTrigger')
              }
              this.generatedIndex = -1
              this.$notify({
                type: 'success',
                title: 'Succès',
                message: this.getSuccessMessage(),
              })
            }
            this.busy = false
            loading.close()
          })
      }
    },
    getInvoiceData(soldItem: SoldItem, dueDate: Date, index: number) {
      const data = {
        saleId: Number(this.sale?.id),
        structureId: Number(soldItem.structureId),
        type: this.documentType === 'receipt' ? 'bordereau' : 'decompte',
        state: 'proforma',
        invoicingDate: new Date(),
        dueDate: dueDate,
        index,
      } as NewInvoiceData
      if (this.documentType === 'receipt') {
        data.buyerId = Number(soldItem.buyerId)
      } else if (this.documentType === 'bill') {
        data.sellerId = Number(soldItem.sellerId) // TODO : remove, peut etre deduit coté api du projectId
        data.requerantId = Number(soldItem.requerantId) // TODO : remove, peut etre deduit coté api du projectId
        data.projectId = soldItem.projectId ?? null
      }
      return data
    },
    getSuccessMessage() {
      return this.documentType === 'receipt'
        ? 'Bordereau(x) créé(s) avec succès'
        : 'Décompte(s) vendeur créé(s) avec succès'
    },
    generateAllReceipts() {
      if (this.generateAllReceiptsTrigger) {
        this.$confirm('Êtes-vous sûr(e) de vouloir générer tous les bordereaux de cette vente ?', 'Confirmation', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }).then(() => {
          const toGenerate = [] as NewInvoiceData[]
          let index = 0
          ;(this.documentsToGenerate as SoldItem[]).forEach((document: SoldItem) => {
            const dueDate = new Date()
            const duePeriod = document?.duePeriod ?? 0
            dueDate.setDate(dueDate.getDate() + duePeriod)
            // index variable is here to avoid multiple invoice with same temporary invoiceNumber, see createTemporaryInvoiceNumber() in InvoiceHelper.php (API)
            const invoiceData = this.getInvoiceData(document, dueDate, index)
            toGenerate.push(invoiceData)
            index++
          })
          this.generatedIndex = index
          this.generate(toGenerate, 'bordereau', true)
        })
      }
    },
    generateAllBills() {
      if (this.generateAllBillsTrigger) {
        this.$confirm('Êtes-vous sûr(e) de vouloir générer tous les décomptes de cette vente ?', 'Confirmation', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }).then(() => {
          const toGenerate = [] as NewInvoiceData[]
          let index = 0
          ;(this.documentsToGenerate as SoldItem[]).forEach((document: SoldItem) => {
            const dueDate = new Date()
            const duePeriod = document?.duePeriod ?? 0
            dueDate.setDate(dueDate.getDate() + duePeriod)
            // index variable is here to avoid multiple invoice with same temporary invoiceNumber, see createTemporaryInvoiceNumber() in InvoiceHelper.php (API)
            const invoiceData = this.getInvoiceData(document, dueDate, index)
            toGenerate.push(invoiceData)
            index++
          })
          this.generatedIndex = index
          this.generate(toGenerate, 'decompte', true)
        })
      }
    },
    currencyFormatter(row: SoldItem) {
      return this.formatCurrency(Number(row.total))
    },
    totalSort(itemA: SoldItem, itemB: SoldItem) {
      const totalA = this.stringToFloat(itemA.total)
      const totalB = this.stringToFloat(itemB.total)
      if (totalA == totalB) {
        return 0
      }

      return totalA < totalB ? -1 : 1
    },
    contactSort(itemA: SoldItem, itemB: SoldItem) {
      const fullNameA =
        this.getFullName(itemA).charAt(0).toUpperCase() + this.getFullName(itemA).slice(1).toLowerCase()
      const fullNameB =
        this.getFullName(itemB).charAt(0).toUpperCase() + this.getFullName(itemB).slice(1).toLowerCase()
      if (fullNameA == fullNameB) {
        return 0
      }

      return fullNameA < fullNameB ? -1 : 1
    },
    addressSort(itemA: SoldItem, itemB: SoldItem) {
      const fullAddressA = this.getFullAddress(itemA)
      const fullAddressB = this.getFullAddress(itemB)
      if (fullAddressA == fullAddressB) {
        return 0
      }

      return fullAddressA < fullAddressB ? -1 : 1
    },
    structureSort(itemA: SoldItem, itemB: SoldItem) {
      const structureA = itemA.structureName ?? ''
      const structureB = itemB.structureName ?? ''
      if (structureA == structureB) {
        return 0
      }

      return structureA < structureB ? -1 : 1
    },
    stringToFloat(str?: number | null) {
      return parseFloat(String(str))
    },
  },
})
