














































































import Vue from 'vue'
import { ApiResponse, Invoice, Sale, SoldItem } from '@/interfaces'
import { mapState } from 'vuex'
import InvoicesTable from '@/components/InvoicesTable.vue'
import ReceiptList from '@/components/Sales/ReceiptList.vue'

interface Stats {
  countItems: number
  total: number
  countItemsInReceipts: number
  countItemsInBills: number
}

export default Vue.extend({
  name: 'Acquirers',
  components: { InvoicesTable, ReceiptList },
  props: {
    sale: {
      type: Object as () => Sale,
      required: false,
      default: null,
    },
    soldItemsLength: {
      type: Number,
      required: false,
      default: null,
    },
    activeTab: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      busy: false,
      loading: false,
      invoices: [] as Invoice[],
      receiptsToGenerate: [] as SoldItem[],
      viewportWidth: 0,
      generateAllReceiptsTrigger: false,
      deleteAllReceiptsTrigger: false,
      stats: {} as Stats,
      firstLoad: false,
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    sale(newVal) {
      if (Number.isInteger(newVal.id) && this.activeTab === 'acquirers' && !this.firstLoad) {
        //this.invoices = newVal.invoices.filter((invoice: Invoice) => invoice.type === 'bordereau') ?? []
        //this.getReceiptToGenerate()
        this.getInvoices() // Load receipt to generate at the end.
        this.firstLoad = true
      }
    },
    activeTab(newVal) {
      if (newVal === 'acquirers' && this.sale.id && !this.firstLoad) {
        //this.invoices = this.sale?.invoices?.filter((invoice: Invoice) => invoice.type === 'bordereau') ?? []
        //this.getReceiptToGenerate()
        this.getInvoices() // Load receipt to generate at the end.
        this.firstLoad = true
      }
    },
  },
  mounted() {
    // At monted the sale is never yet loaded
    // if (this.sale && Number.isInteger(this.sale?.id) && this.activeTab === 'acquirers') {
    //   //this.invoices = this.sale?.invoices?.filter((invoice: Invoice) => invoice.type === 'bordereau') ?? []
    //   //this.getReceiptToGenerate()
    //   this.getInvoices() // Load receipt to generate at the end.
    // }
    this.viewportWidth = window.innerWidth

    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.viewportWidth = window.innerWidth
    },
    displayProgress() {
      // let invoicedItemsNumber = 0
      // if (this.invoices) {
      //   this.invoices.forEach((invoice: Invoice) => {
      //     invoicedItemsNumber += invoice?.requisitionItemsAsReceiptCount ?? 0
      //   })
      // }
      // return invoicedItemsNumber
      return this.stats.countItemsInReceipts
    },
    getReceiptToGenerate(force = false) {
      if (this.receiptsToGenerate.length === 0 || force) {
        const id = this.sale?.id

        this.$api
          .get(`/receipt/sale/${id}`)
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.receiptsToGenerate = apiResponse.data.items
            this.stats = apiResponse.data.stats
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Merci de reéssayer plus tard.',
            })
          })
      }
    },
    getInvoices() {
      if (!this.busy) {
        this.busy = true

        const loading = this.$loading({
          target: '#invoicesTable',
          text: 'Chargement des données...',
        })

        this.$api
          .get(`/sale/${this.sale?.id}/invoices/list/bordereau`)
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.invoices = apiResponse.data
          })
          .finally(() => {
            loading.close()
            this.busy = false
            this.getReceiptToGenerate(true)
          })
      }
    },
    pushInvoice(invoice: Invoice) {
      this.invoices.unshift(invoice)
    },
    removeReceipt(index: number) {
      this.receiptsToGenerate.splice(index, 1)
    },
    getProgress(data = ''): number {
      // let invoicedItemsNumber = 0
      // if (this.soldItemsLength > 0 && this.invoices) {
      //   this.invoices.forEach((invoice: Invoice) => {
      //     invoicedItemsNumber += invoice?.requisitionItemsAsReceiptCount ?? 0
      //   })
      //   if (data === 'soldItems') return this.soldItemsLength
      //   if (invoicedItemsNumber === 0) {
      //     return 0
      //   }
      //   const percent = (invoicedItemsNumber / this.soldItemsLength) * 100
      //   if (percent > 100) {
      //     return 100
      //   }
      //   return percent
      // }
      // return 0
      if (this.stats.countItems) {
        if (data === 'soldItems') return this.stats.countItems
        const percent = (this.stats.countItemsInReceipts / this.stats.countItems) * 100
        if (percent > 100) {
          return 100
        }
        return percent
      }

      return 0
    },
    generateAllReceipts() {
      this.generateAllReceiptsTrigger = true
    },
    deleteAllReceipts() {
      this.deleteAllReceiptsTrigger = true
    },
    resetGenerateTrigger() {
      this.generateAllReceiptsTrigger = false
      //this.receiptsToGenerate = []
      // Fin du traitement en masse : A minima pour recalculer les stats
      this.getReceiptToGenerate(true)
    },
    resetDeleteTrigger() {
      this.deleteAllReceiptsTrigger = false
    },
  },
})
